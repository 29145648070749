<template>
  <div class="lecturer">
    <!-- 人气讲师 -->
    <!-- <div class="popularity-content">
      <div class="popularity-title">人气讲师<span class="popularity-tip">这是一段针对于讲师的描述</span></div>
      <ul class="popularity-list">
        <li class="popularity-info">
          <img class="popularity-img" src="">
          <div class="popularity-name">郭晋龙</div>
          <div class="popularity-the-title">中工课程高级讲师</div>
        </li>
      </ul>
    </div> -->
    <!-- 筛选条件 -->
    <div class="filtrate">
      <div class="filtrate-content">
        <template v-if="teacher_list_screen.includes('1')" >
          <div :class="industryState?'filtrate-list autoHeight':'filtrate-list'">
          <span class="filtrate-title">行业：</span>
          <div class="filtrate-options">
            <span class="filtrate-option" :class="{'active': industry==0}" @click="industryBtn(0)">不限</span>
            <span class="filtrate-option" :class="{'active': industry==key}" v-for="(value, key) in searchParams.industry" :key="key" @click="industryBtn(key)">{{value}}</span>
          </div>
        </div>
        <div class="more_btn" v-if="!industryState " @click="industryState=!industryState">更多</div> 
         <div class="more_btn" v-if="industryState " @click="industryState=!industryState">收起</div> 
        </template>
        <template v-if="teacher_list_screen.includes('2')" >
          <div  :class="workTypeState?'filtrate-list autoHeight':'filtrate-list'">
          <span class="filtrate-title">工种：</span>
          <div class="filtrate-options">
            <span class="filtrate-option" :class="{'active': work_type==0}" @click="workTypeBtn(0)">不限</span>
            <span class="filtrate-option" :class="{'active': work_type==key}" v-for="(value, key) in searchParams.work_type" :key="key" @click="workTypeBtn(key)">{{value}}</span>
          </div>
        </div>
         <div class="more_btn" v-if="!workTypeState " @click="workTypeState=!workTypeState">更多</div> 
         <div class="more_btn" v-if="workTypeState" @click="workTypeState=!workTypeState">收起</div> 
        </template>
        <template v-if="teacher_list_screen.includes('3')" >
          <div  :class="tdState?'filtrate-list autoHeight':'filtrate-list'">
          <span class="filtrate-title">授课方向：</span>
          <div class="filtrate-options">
            <span class="filtrate-option" :class="{'active': teach_direction==0}" @click="teachDirectionBtn(0)">不限</span>
            <span class="filtrate-option" :class="{'active': teach_direction==key}" v-for="(value, key) in searchParams.teach_direction" :key="key" @click="teachDirectionBtn(key)">{{value}}</span>
          </div>
        </div>
         <div class="more_btn" v-if="!tdState " @click="tdState=!tdState">更多</div> 
         <div class="more_btn" v-if="tdState " @click="tdState=!tdState">收起</div> 
        </template>
        <!-- <div class="filtrate-list">
          <span class="filtrate-title">等级：</span>
          <span class="filtrate-option" :class="{'active': level==0}" @click="getResultsList(industry, 0)">不限</span>
          <span class="filtrate-option" :class="{'active': level==key}" v-for="(value, key) in searchParams.level" :key="key" @click="getResultsList(industry, key)">{{value}}</span>
        </div> -->
        <div v-if="siteInfo.id != 107" class="filtrate-list listCont">
          <span class="filtrate-title">是否支持元宇宙跨空间培训: <a href="https://npx.kskstudy.com/activity/detail?id=32" target="_blank" rel="noopener noreferrer">
                 <img src="@/assets/tip_icon.png" alt="">
               </a></span>
          <span class="filtrate-option" :class="{'active': yyzIndex==1}" @click="changYyz('1')">是</span>
          <span class="filtrate-option" :class="{'active': yyzIndex==2}" @click="changYyz('2')">否</span>
        </div> 
        <!-- <div class="more">更多</div> -->
      </div>
    </div>
    <!-- 搜索结果 -->
    <ul class="result-list">
      <li class="result-info" v-for="item in resultsList.data" :key="item.id">
        <p class="img result-img"  @click="goHomePage(item)">
          <img v-if="item.avatar" class="" :src="item.avatar">
          <img v-else class="" src="@/assets/per_img.png">
        </p>
        <div class="result-content">
          <div class="result-name" @click="goHomePage(item)">
            <span>{{item.name}}</span>
            <!-- <div class="marks">
              <div class="mark">{{item.level}}</div>
            </div> -->
          </div>
          <div class="marks"  @click="goHomePage(item)">
            <div class="mark" v-for="mark in item.teacher_tags" :key="mark">{{mark}}</div>
          </div>
          <div class="result-describe"  @click="goHomePage(item)">{{item.introduce}}</div>
          <div class="result-video" v-if="item.course.name" @click="goCourse(item.course)">{{item.course.name}}</div>
        </div>
        <div class="toIndex"><router-link :to="'/lecturerHomepage/'+item.id">进入主页</router-link></div>
      </li>
    </ul>
    <pagination v-if="num>0" :num="num" :limit="limit" @getNew="getNew"></pagination>
  </div>
</template>
<script>
import pagination from "@/components/pc/pagination.vue";
export default {
  name: 'lecturer',
  data(){
    return {
      searchParams: {},  //搜索条件列表
      resultsList: '',   //搜索结果列表
      num: 0,            //数据总个数
      limit: 10,         //一页显示几个
      nowPage: 1,        //当前页码
      industry: 0,       //行业
      work_type: 0,       //工种
      teach_direction: 0,       //授课方向
      level: 0,           //等级
      teacher_list_screen:'',//筛选栏
      WebInfo:{},//网站信息
      industryState:false,
      workTypeState:false,
      tdState:false,
      yyzIndex:1,
      siteInfo: "",
    }
  },
  components:{
    pagination
  },
  mounted(){
    this.siteInfo = JSON.parse(localStorage.getItem("siteInfo")); //网站信息
    this.getSearchParams();      //获取讲师列表
    this.WebInfo = JSON.parse(localStorage.getItem('siteInfo'))
    this.teacher_list_screen = this.WebInfo.teacher_list_screen.join(',')
  },
  methods: {
    //获取讲师列表
    getSearchParams(){
      this.$axios.post(`/v1/teacher/searchParams`).then(res => {
        this.searchParams = res.data;
        this.getResultsList()
      });
    },
    // 筛选条件
    industryBtn(key){
      this.industry = key
      this.getResultsList()
      this.resultsList=[]
    },
    workTypeBtn(key){
      this.work_type = key
      this.getResultsList()
      this.resultsList=[]

    },
    teachDirectionBtn(key){
      this.teach_direction = key
      this.getResultsList()
      this.resultsList=[]
    },
    //获取讲师列表
    getResultsList(industry, work_type,teach_direction,level){
      // if(industry){
      //   this.industry = industry;
      //   this.num = 0;
      // }else{
      //   this.industry = 0;
      // }
      // if(work_type){
      //   this.work_type = work_type;
      //   this.num = 0;
      // }else{
      //   this.work_type = 0;
      // }
      // if(teach_direction){
      //   this.teach_direction = teach_direction;
      //   this.num = 0;
      // }else{
      //   this.teach_direction = 0;
      // }

      // if(level){
      //   this.level = level;
      //   this.num = 0;
      // }else{
      //   this.level = 0;
      // }
      if(this.yyzIndex==2){
        this.num = 0;
        this.resultsList = [];
        return
      }

      this.$axios.post(`/v1/teacher/list`,{
        industry: this.industry,
        level: this.level,
        work_type:this.work_type,
        teach_direction:this.teach_direction,
        page: this.nowPage,
        pre_page: this.limit
      }).then(res => {
        this.num = res.data.total;
        this.resultsList = res.data;
      });
    },
    changYyz(num){
      this.yyzIndex = num
      this.getResultsList()
    },
    goHomePage(item){
      this.$router.push('/lecturerHomepage/'+item.id)
    },
    // 跳转到课程页
    goCourse(item){
      if(item.course_id){
        this.$router.push('/recordedInfo/'+item.course_id)
      }
    },
    //翻页
    getNew(nowPage) {
      this.nowPage = nowPage;
      this.getResultsList();
    },
  }
}
</script>
<style scoped lang="scss">
.lecturer{
  background: #f9f9f9;
}
.popularity-content{
  width: 1200px;
  padding:34px 0 44px;
  background-color: #fff;
  margin: 0 auto;
}
.popularity-title{
  font-size: 24px;
  font-weight: 800;
  color: #333;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  margin-bottom: 44px;
}
.popularity-tip{
  font-size: 16px;
  color: #666;
  margin-left: 14px;
}
.popularity-list{
  display: flex;
  justify-content: space-between;
}
.popularity-info{
  width: 220px;
  text-align: center;
}
.popularity-img{
  width: 100%;
  height: 290px;
  margin-bottom: 14px;
}
.popularity-name{
  font-size: 24px;
  font-weight: 900;
  color: #333;
  letter-spacing: 1px;
  margin-bottom: 12px;
}
.popularity-the-title{
  font-size: 18px;
  color: #666;
}
.filtrate{
  padding: 20px 0 0;
  background-color: #fff;
}
.filtrate-list{
  align-items: flex-start;
  white-space: nowrap;
  height: 45px;
  overflow: hidden;
  img{
    width: 20px;
  }
}
.listCont{
  display: flex;
  align-items: flex-end;
  padding-top: 0 !important;
  padding-bottom: 30px;
  span{
    margin-bottom: 0 !important;
  }
}
.filtrate-list.autoHeight{
  height: auto;
}
.filtrate-list:first-child{
  // border-bottom: 1px solid #E5E5E5;
}
.filtrate-options{
  display: flex;
  flex-wrap: wrap;
}
.filtrate-option{
  margin-bottom: 10px;
}
.result-list{
  width: 1200px;
  margin: 0 auto;
  padding-top: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .result-info{
    box-sizing: border-box;
    width: 588px;
    padding: 10px;
    background-color: #FFF;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    margin-bottom: 24px;
  }
  .result-img{
    width: 220px;
    height: 220px;
    float: left;
    overflow: hidden;
    margin-right: 14px;
    img{
      width: 100%;
      border-radius: 6px;
    }
  }
  .result-content{
    width: 320px;
    float: left;
  }
  .result-name{
    padding-top: 15px;
    font-size: 16px;
    font-weight: 800;
    color: #333;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: default;
    .marks{
      margin: 0 0 0 14px;
      .mark{
        background: rgba(255, 195, 11, 0.14);
        color: #F5A623;
      }
    }
  }
  .result-describe{
    height: 48px;
    font-size: 14px;
    color: #999;
    line-height: 24px;
    //多行省略号
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 35px;
    cursor: default;
  }
  .result-video{
    box-sizing: border-box;
    width: 100%;
    padding-left: 20px;
    min-height: 16px;
    font-size: 12px;
    color: #999;
    background: url(~@/assets/play-ico.png) no-repeat left center;
    background-size: 16px;
    cursor: pointer;
  }
  .toIndex{
    min-height: 12px;
    padding-right: 24px;
    background: url(~@/assets/arrows-right-blue-ico.png) no-repeat right center;
    background-size: 14px 12px;
    position: absolute;
    right: 25px;
    bottom: 20px;
    a{
      font-size: 14px;
      color: #254ED4;
    }
  }
}
.page-wrap{
  padding-bottom: 30px;
}
.more_btn{
  text-align: center;
  padding-bottom: 20px;
  font-size: 16px;
  color: #999;
  cursor: pointer;
  margin-top: 20px;
}
</style>